.tiptap p{
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
}

[contenteditable]{
    -webkit-user-select: text;
            user-select: text;
}

.ProseMirror{
    -webkit-user-select: text;
            user-select: text;
    /* writing-mode: vertical-rl; */
}
/* *{
    touch-action: none;
} */